@import "src/styles/_colors.scss";

.report--menu--tabs {
  width: 100%;
  //overflow-y: scroll;
  display: flex;

  .report--menu-body {
    width: 552px;

    nav a {
      flex-grow: 1;
      text-align: center;
    }

    .nav.nav-tabs {
      margin-bottom: 0;
    }

    .nav-tabs .nav-link {
      border-bottom: 1px solid #dee2e6;
    }

    .nav-tabs .nav-link.active {
      color: #009ee0;
      background-color: #ffffff;
      border-color: #dee2e6 #dee2e6 #009ee0;
      border-bottom-width: 3px;
    }

    &--details-container {
      .gaia-detail-result--header {
        h1 {
          font-size: 1.5rem;
          margin-top: 0.7rem;
          font-family: "DINPro-Regular", sans-serif;
        }

        h3 {
          font-weight: 100;
          font-size: 0.7rem;
          font-family: "DINPro-Regular", sans-serif;
        }
      }
    }

    tr .cell-width:first-child {
      width: 50%;
    }

    .gaia-td-align-right {
      text-align: right;
    }

    &--details_no-data {
      padding: 10px 10px 0;
      font-size: 1rem;
    }
  }

  .report--menu_collapsed--body {
    writing-mode: vertical-lr;
    text-orientation: mixed;
    font-size: 25px;
    width: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-disabled {
    color: #6c757d;
  }
  .report--menu--tabs_list--button {
    background: $primary-color;
    color: white;
    display: inline-flex;
    margin-left: 10px;
    border: 1px solid transparent;
    box-shadow: unset;

    &.disabled {
      background: $fourth;
      cursor: not-allowed;
    }

    &.dropdown {
      margin-left: 0;
    }

    &.dropdown-toggle {
      &:hover,
      &:focus,
      &:active {
        background-color: $primary-color;
        color: white;
        border-color: transparent;
      }
      &:after {
        margin-top: 8px;
      }
    }
  }

  .report--menu--tabs_list--input-container {
    display: flex;
    width: 100%;

    .sz-form-group {
      width: 100%;
      &__sz-input-text {
        height: 30px;
      }
    }

    .sz-icon-line {
      display: inline-flex;
      margin-left: 5px;
      align-items: center;
    }
  }
  .hidden {
    display: none !important;
  }

  &_list {
    display: flex;
    flex-flow: column;
    height: 100%;

    &--header {
      padding: 17px 10px 5px;
      justify-content: space-evenly;
      border-bottom: 1px solid $fourth-lighter;
      display: flex;
      flex-flow: column;

      &_title {
        padding: 0 10px;

        h2 {
          padding: 10px;
          border: 1px solid $fourth-lighter;
          font-size: 1.125rem;
          box-shadow: rgba(0, 0, 0, 0.2) 0 0 3px 1px;
        }
        .listType {
          border: 1px solid #cfdbf9b3 !important;
          font-size: 1.125rem;
          font-family: "DINPro-Bold";
          font-weight: 900;
          line-height: 1.2;
        }
      }

      &_buttons {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
      }

      &_buttons_noflex {
        padding: 5px 10px;
      }
    }

    &--fav {
      background-color: #fff;
      padding: 5px 10px 5px 5px;
      display: flex;
      flex-wrap: wrap;

      .fav-filters {
        width: 100%;
      }

      .css-pcvwr-control,
      .css-u6f56q-control {
        min-height: 40px;
      }

      .fav-filters_cog-wheel {
        display: flex;
        align-items: center;
        justify-items: center;
      }

      &--button-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
    }

    &--filter {
      &-sub-label {
        display: inline-flex;
        width: 50%;
        padding-left: 5px;
        color: grey;

        &_container {
          width: 100%;
          display: flex;
        }
      }

      &-label {
        display: inline-flex;
        width: 60%;
        padding-left: 5px;

        &_container {
          width: 100%;
          display: flex;
        }
      }

      &-container {
        padding: 0 15px 10px;
        overflow-y: scroll;
        display: flex;
        flex-flow: column;
        height:100%;

        .sz-form-group__sz-input-text {
          height: 40px;
        }

        .css-pcvwr-control,
        .css-u6f56q-control {
          min-height: 40px;
        }

        .pb-0:last-of-type {
          padding-bottom: inherit !important;
        }

        &_title {
          padding: 5px 10px 0;
          margin-bottom: 0.5rem;
          font-size: 13px;
        }

        .react-date-picker {
          &__inputGroup {
            font-weight: 100;
            font-family: "DINPro-Regular", sans-serif;
          }

          &__button,
          .arrow-down-1 {
            padding-top: 5px;
          }

          &:last-child > div {
            border-left: 1px solid var(--light);

            &:hover {
              border-left: 1px solid $secondary-color;
            }
          }

          &__wrapper {
            border: 1px solid var(--light);

            &:hover {
              border: 1px solid $secondary-color;
            }

            &:before {
              content: "\f120";
            }
          }
        }

        .input_group {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          padding: 0.5rem 0;

          .sector,
          .city,
          .conformity,
          .majorFaultType,
          .propertyType,
          .connectionNumber,
          .geoserverBV,
          .emOperatingSector,
          .networkOperatingSector,
          .status,
          .zac,
          .category,
          .origin,
          .controlType,
          .veConformity,
          .vptConformity,
          .rate,
          .type,
          .criteria {
            width: 100%;
            border-color: $secondary-color !important;
          }

          .connectionNumber div {
            margin-bottom: 0 !important;
          }

          .visitType {
            width: 100%;
            border-color: $secondary-color !important;
          }

          label {
            font-size: 13px;
            text-transform: none !important;
          }

          .react-date-picker {
            height: 42px;
          }

          .sz-checkbox {
            padding: 5px 9px;
            z-index: auto;

            label {
              white-space: nowrap;
            }
          }

          .sz-form-group {
            width: 100%;

            &__sz-input-text {
              background-color: transparent !important;
              border: 1px solid #e7e8ee;

              &:hover,
              &:focus {
                border-color: $secondary-color;
              }

              &::placeholder {
                font-style: normal;
                font-family: "DINPro-Regular";
              }
            }
          }

          .form-group {
            display: inline-flex;
            align-items: center;
            flex-grow: 1;
            margin: 0;

            label,
            .sz-container-select {
              display: inline-flex;
              margin-bottom: 0;
            }

            .sz-container-select {
              display: inline-flex;
              flex-grow: 1;
              margin-left: 10px;
              margin-bottom: 0 !important;
              padding-top: 0.1rem !important;
              padding-bottom: 0.1rem !important;
              border-color: $secondary-color !important;

              &__chevron {
                top: 8px;
              }
            }
          }
        }
      }
    }
  }
}
