@import "./styles/_colors";
@import './styles/fonts';

html, body, #gaia-app {
  height: 100%;
  background-color: #EAEDF4;
  font-family: 'DINPro-Regular';
}
.gaia-content-container {
  max-width: 1100px;
}

.text-secondary {
  color: $primary-color !important;
}

.sz-aside__item.active, .sz-aside__item.active:hover, , .sz-aside__item.active:visited {
  color: $primary-color;
  background: none;
}
