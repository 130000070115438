@import "../../../styles/colors";

.search-suggestions {

  position: absolute;
  left: 0;
  top: 45px;
  width: 560px;
  background-color: #fff;
  border: solid $secondary-color;
  border-width: 1px 2px 2px 1px;
  z-index: 20;

  &-header {
    background-color: $fourth-lighter;
    font-size: 1rem;
    font-weight: bold;
    padding: 5px;
    color: #030f40;
  }

  &-loader {
    padding: 0 10px;
  }

  &-item{
    display: flex;
    color: $third;
    cursor: pointer;

    &-container {
      padding: 8px 3px;
      margin: 0 5px;
      width: 100%;
      border-top: 1px solid $third;
    }

    &:hover {
      background-color: $fourth-lighter;
    }

    .suggestion-icon{
      font-size: 1.2rem;
      margin-right: 20px;
    }

  }
}
