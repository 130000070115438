.map{
    width: 100%;
    height: 100%;
}

.map3d{
    width: 100%;
    height: 100%;
}

.smartshape{
    width: 100%;
    height: 100%;
}

.display-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.hidden {
    display: none;
}
