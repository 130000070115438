@import url(https://js.arcgis.com/4.27/esri/css/main.css);
html, body, #root {
  height: 100%;
  overflow: hidden; }
  html #launcher-frame, body #launcher-frame, #root #launcher-frame {
    right: 73px !important;
    top: 0px; }

.sz-underline::after {
  background-color: #239de1; }

.gaia-detail-result {
  width: 100%;
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  box-shadow: -2px -2px 5px -1px rgba(0, 0, 0, 0.76);
  display: -webkit-flex;
  display: flex; }
  .gaia-detail-result-container {
    display: -webkit-inline-flex;
    display: inline-flex; }
  .gaia-detail-result--header {
    display: -webkit-flex;
    display: flex; }
    .gaia-detail-result--header--primary-title, .gaia-detail-result--header--secondary-title {
      margin-left: 10px; }
  .gaia-detail-result--nav {
    height: 57px;
    border-top: 1px solid #cfdbf9b3; }
    .gaia-detail-result--nav .nav-link {
      padding: 0.5rem 0.5rem; }
      .gaia-detail-result--nav .nav-link.active, .gaia-detail-result--nav .nav-link:hover {
        color: #239de1;
        background: inherit;
        border-bottom: 4px solid #239de1; }
  .gaia-detail-result .tab-content {
    height: calc(100% - 204px);
    overflow-y: scroll; }
  .gaia-detail-result .gaia-detail-result--expanded-body {
    width: 562px; }
    .gaia-detail-result .gaia-detail-result--expanded-body table td,
    .gaia-detail-result .gaia-detail-result--expanded-body table th {
      border: none; }
  .gaia-detail-result .item-header {
    width: 93%; }
  .gaia-detail-result .item-type {
    font-size: 1.5rem; }
    .gaia-detail-result .item-type i {
      float: right;
      padding-right: 8px;
      padding-top: 9px; }
  .gaia-detail-result p {
    margin-bottom: 0.5rem; }
  .gaia-detail-result tr .cell-width:first-child {
    width: 50%; }
  .gaia-detail-result .gaia-td-align-left {
    text-align: left; }
  .gaia-detail-result .gaia-td-align-right {
    text-align: right; }
  .gaia-detail-result .secondary-title {
    font-weight: 100;
    font-size: 0.7rem;
    font-family: "DINPro-Regular", sans-serif; }
  .gaia-detail-result .primary-title {
    font-size: 1.5rem;
    margin-top: 0.7rem;
    font-family: "DINPro-Regular", sans-serif; }
  .gaia-detail-result .primary-title-category {
    font-size: 1.2rem;
    margin-top: 0.7rem;
    font-family: "DINPro-Regular", sans-serif;
    color: #239de1; }
  .gaia-detail-result .container-bordered .card-header {
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important; }
  .gaia-detail-result .accordion .card {
    border: none !important; }
  .gaia-detail-result .gaia-detail-result--collapsed-body {
    width: 78px;
    overflow-y: scroll;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-writing-mode: vertical-lr;
        -ms-writing-mode: tb-lr;
            writing-mode: vertical-lr;
    -webkit-text-orientation: mixed;
            text-orientation: mixed;
    cursor: pointer; }
    .gaia-detail-result .gaia-detail-result--collapsed-body .gaia-detail-result--collapsed-body--title {
      font-size: 25px; }
  .gaia-detail-result .mdi.visit-ok {
    color: #00ff00; }
  .gaia-detail-result .mdi.visit-ko {
    color: #ff0000; }
  .gaia-detail-result--documents-container {
    padding: 15px 10px; }
    .gaia-detail-result--documents-container--doc {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: column;
              flex-flow: column;
      cursor: pointer; }
      .gaia-detail-result--documents-container--doc-title {
        word-break: break-word;
        font-size: 12px; }
      .gaia-detail-result--documents-container--doc-icon {
        max-width: 50%;
        -webkit-align-self: center;
                align-self: center;
        margin: 20px 0 2px; }

.report--menu-body--details-container {
  height: 100%; }
  .report--menu-body--details-container .tab-content {
    height: calc(100% - 204px);
    overflow-y: scroll; }
    .report--menu-body--details-container .tab-content .container-bordered .card-header {
      border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important; }
    .report--menu-body--details-container .tab-content .accordion .card {
      border: none !important; }

.thematicMap--menu-body--details-container {
  height: 100%; }
  .thematicMap--menu-body--details-container .tab-content {
    height: calc(100% - 204px);
    overflow-y: scroll; }

.table-overflow {
  overflow: auto;
  display: block;
  table-layout: auto; }

.card-header i {
  float: right;
  font-size: 1rem; }

.work3d .container-bordered .card-header {
  border-top: none !important;
  border-bottom: none !important;
  background-color: white; }

.project {
  height: 100%; }
  .project .project-header {
    display: block !important; }

.signal {
  height: 100%; }
  .signal .signal-header {
    display: block !important; }

.siteIndustriel {
  height: 100%; }
  .siteIndustriel .siteIndustriel-header {
    display: block !important; }

.work3d .gaia-search-data__item {
  opacity: 1;
  list-style-type: none;
  padding: 15px 2%;
  letter-spacing: 0;
  color: #072b4e;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }

.work3d .gaia-search-data__item.active {
  font-weight: bold;
  padding: 20px 2%;
  color: #009ee0; }

.work3d .gaia-search-data__item-links {
  width: 10%; }

.work3d .gaia-search-data__item-content {
  width: 80%; }

.work3d .card-header i {
  float: left;
  font-size: 0.5rem;
  padding-top: 8px;
  padding-right: 16px; }

.work3d .gaia_breadcrumbs {
  background-color: white; }

.btn-light {
  background: linear-gradient(to left, #d1d2ce, var(--light)) repeat-x;
  border-color: var(--light); }

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  float: right; }

.btn-light:hover {
  background: linear-gradient(to left, #dff9fb, var(--light)) repeat-x;
  border-color: var(--light); }

.gaia-pdf {
  float: left;
  max-width: 100%;
  height: 85%;
  overflow: scroll; }

.gaia-progress-bar {
  width: 35%;
  margin-left: 90%; }

.Toolbar {
  right: 0;
  left: 0;
  top: 5%;
  width: 100%;
  background-color: #222;
  border: 2px solid #888;
  border-radius: 7px;
  padding: 2px;
  z-index: 10;
  text-align: center; }

.Toolbar > .ZoomIn,
.Toolbar > .ZoomOut {
  border: 1px solid #888;
  border-radius: 5px;
  background-color: #FE8;
  width: 32px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin: 2px; }

.Toolbar > .ZoomPercent {
  display: inline;
  display: initial;
  font-size: 16px;
  color: white;
  margin: 2px; }

.collapse-toggle {
  position: relative;
  left: 0;
  height: 100%;
  background: #fff;
  width: 18px;
  border-right: 2px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125); }
  .collapse-toggle .collapse-toggle-hover-box {
    background-color: transparent;
    position: absolute;
    width: 15px;
    height: 100px;
    top: 0;
    right: -1px;
    transition-duration: 0.35s;
    z-index: 1; }
    .collapse-toggle .collapse-toggle-hover-box .collapse-toggle-container {
      position: absolute;
      top: 20%;
      width: 13px;
      left: 0;
      height: 40px;
      background-color: var(--primary);
      cursor: pointer; }
      .collapse-toggle .collapse-toggle-hover-box .collapse-toggle-container .collapse-toggle-default {
        color: white;
        opacity: 1;
        left: -5px;
        top: 6px;
        position: absolute; }

.autocomplete {
  width: 100%; }
  .autocomplete .mb-3 {
    margin-bottom: 0 !important; }
  .autocomplete .autocomplete-input input {
    font-family: "DINPro-Regular", sans-serif; }
  .autocomplete-options {
    border: 1px solid #cfdbf9b3; }
    .autocomplete-options-header {
      padding: 5px;
      font-size: 1rem;
      font-weight: bold;
      background-color: #cfdbf9b3; }
    .autocomplete-options-loader {
      padding: 0 10px; }
    .autocomplete-options--option {
      padding: 5px;
      cursor: pointer; }
      .autocomplete-options--option:hover {
        background-color: #cfdbf9b3; }
      .autocomplete-options--option_none {
        font-style: italic;
        background-color: #AFB8CE;
        color: white; }
        .autocomplete-options--option_none:hover {
          background-color: #AFB8CE;
          cursor: not-allowed; }

.sz-form-group {
  height: 100%; }
  .sz-form-group__input-group-icon {
    z-index: 1 !important; }

.sz-form-group__input-group-icon {
  z-index: 5;
  width: 45px;
  font-size: 1.12rem;
  border: white 0.5px solid;
  background-color: #030e40;
  color: white !important;
  cursor: pointer; }
  .sz-form-group__input-group-icon:focus, .sz-form-group__input-group-icon:active {
    background-color: #030e40;
    color: white !important; }
    .sz-form-group__input-group-icon:focus + .focused, .sz-form-group__input-group-icon:active + .focused {
      background-color: #030e40;
      color: white !important;
      border: 2px solid #239de1;
      border-left: none; }

.sz-form-group__sz-input-text:focus {
  border: 2px solid #239de1; }

.sz-form-group .spinner-border {
  z-index: 5;
  left: 516px;
  top: 2px;
  font-size: 0.12rem; }

.gaia-report-fav-filter_update-modal {
  display: -webkit-flex;
  display: flex; }
  .gaia-report-fav-filter_update-modal-filter {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid #030e40;
    margin-bottom: 10px; }
    .gaia-report-fav-filter_update-modal-filter-title {
      display: -webkit-inline-flex;
      display: inline-flex; }
    .gaia-report-fav-filter_update-modal-filter-actions {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-shrink: 0;
              flex-shrink: 0; }
      .gaia-report-fav-filter_update-modal-filter-actions_checkbox {
        display: -webkit-inline-flex;
        display: inline-flex; }
      .gaia-report-fav-filter_update-modal-filter-actions_radio {
        display: -webkit-inline-flex;
        display: inline-flex; }
        .gaia-report-fav-filter_update-modal-filter-actions_radio.sz-radio .custom-control-label::before {
          top: 0.15625rem; }
  .gaia-report-fav-filter_update-modal-footer {
    margin-top: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .gaia-report-fav-filter_update-modal-footer .no-default-fav {
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      width: 100%; }
    .gaia-report-fav-filter_update-modal-footer button {
      width: 150px;
      margin-right: 20px; }

.sz-form-group {
  height: 100%; }
  .sz-form-group__input-group-icon {
    z-index: 1 !important; }

.sz-form-group__input-group-icon {
  z-index: 5;
  width: 45px;
  font-size: 1.12rem;
  border: white 0.5px solid;
  background-color: #030e40;
  color: white !important;
  cursor: pointer; }
  .sz-form-group__input-group-icon:focus, .sz-form-group__input-group-icon:active {
    background-color: #030e40;
    color: white !important; }
    .sz-form-group__input-group-icon:focus + .focused, .sz-form-group__input-group-icon:active + .focused {
      background-color: #030e40;
      color: white !important;
      border: 2px solid #239de1;
      border-left: none; }

.sz-form-group__sz-input-text:focus {
  border: 2px solid #239de1; }

.sz-form-group .spinner-border {
  z-index: 5;
  left: 516px;
  top: 2px;
  font-size: 0.12rem; }

.gaia-report-fav-filter_update-modal {
  display: -webkit-flex;
  display: flex; }
  .gaia-report-fav-filter_update-modal-filter {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid #030e40;
    margin-bottom: 10px; }
    .gaia-report-fav-filter_update-modal-filter-title {
      display: -webkit-inline-flex;
      display: inline-flex; }
    .gaia-report-fav-filter_update-modal-filter-actions {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-shrink: 0;
              flex-shrink: 0; }
      .gaia-report-fav-filter_update-modal-filter-actions_checkbox {
        display: -webkit-inline-flex;
        display: inline-flex; }
      .gaia-report-fav-filter_update-modal-filter-actions_radio {
        display: -webkit-inline-flex;
        display: inline-flex; }
        .gaia-report-fav-filter_update-modal-filter-actions_radio.sz-radio .custom-control-label::before {
          top: 0.15625rem; }
  .gaia-report-fav-filter_update-modal-footer {
    margin-top: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .gaia-report-fav-filter_update-modal-footer .no-default-fav {
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      width: 100%; }
    .gaia-report-fav-filter_update-modal-footer button {
      width: 150px;
      margin-right: 20px; }

.sz-form-group {
  height: 100%; }
  .sz-form-group__input-group-icon {
    z-index: 1 !important; }

.sz-form-group__input-group-icon {
  z-index: 5;
  width: 45px;
  font-size: 1.12rem;
  border: white 0.5px solid;
  background-color: #030e40;
  color: white !important;
  cursor: pointer; }
  .sz-form-group__input-group-icon:focus, .sz-form-group__input-group-icon:active {
    background-color: #030e40;
    color: white !important; }
    .sz-form-group__input-group-icon:focus + .focused, .sz-form-group__input-group-icon:active + .focused {
      background-color: #030e40;
      color: white !important;
      border: 2px solid #239de1;
      border-left: none; }

.sz-form-group__sz-input-text:focus {
  border: 2px solid #239de1; }

.sz-form-group .spinner-border {
  z-index: 5;
  left: 516px;
  top: 2px;
  font-size: 0.12rem; }

.gaia-report-fav-filter_update-modal {
  display: -webkit-flex;
  display: flex; }
  .gaia-report-fav-filter_update-modal-filter {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid #030e40;
    margin-bottom: 10px; }
    .gaia-report-fav-filter_update-modal-filter-title {
      display: -webkit-inline-flex;
      display: inline-flex; }
    .gaia-report-fav-filter_update-modal-filter-actions {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-shrink: 0;
              flex-shrink: 0; }
      .gaia-report-fav-filter_update-modal-filter-actions_checkbox {
        display: -webkit-inline-flex;
        display: inline-flex; }
      .gaia-report-fav-filter_update-modal-filter-actions_radio {
        display: -webkit-inline-flex;
        display: inline-flex; }
        .gaia-report-fav-filter_update-modal-filter-actions_radio.sz-radio .custom-control-label::before {
          top: 0.15625rem; }
  .gaia-report-fav-filter_update-modal-footer {
    margin-top: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .gaia-report-fav-filter_update-modal-footer .no-default-fav {
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      width: 100%; }
    .gaia-report-fav-filter_update-modal-footer button {
      width: 150px;
      margin-right: 20px; }

.sz-form-group {
  height: 100%; }
  .sz-form-group__input-group-icon {
    z-index: 1 !important; }

.sz-form-group__input-group-icon {
  z-index: 5;
  width: 45px;
  font-size: 1.12rem;
  border: white 0.5px solid;
  background-color: #030e40;
  color: white !important;
  cursor: pointer; }
  .sz-form-group__input-group-icon:focus, .sz-form-group__input-group-icon:active {
    background-color: #030e40;
    color: white !important; }
    .sz-form-group__input-group-icon:focus + .focused, .sz-form-group__input-group-icon:active + .focused {
      background-color: #030e40;
      color: white !important;
      border: 2px solid #239de1;
      border-left: none; }

.sz-form-group__sz-input-text:focus {
  border: 2px solid #239de1; }

.sz-form-group .spinner-border {
  z-index: 5;
  left: 516px;
  top: 2px;
  font-size: 0.12rem; }

.gaia-report-fav-filter_update-modal {
  display: -webkit-flex;
  display: flex; }
  .gaia-report-fav-filter_update-modal-filter {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid #030e40;
    margin-bottom: 10px; }
    .gaia-report-fav-filter_update-modal-filter-title {
      display: -webkit-inline-flex;
      display: inline-flex; }
    .gaia-report-fav-filter_update-modal-filter-actions {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-shrink: 0;
              flex-shrink: 0; }
      .gaia-report-fav-filter_update-modal-filter-actions_checkbox {
        display: -webkit-inline-flex;
        display: inline-flex; }
      .gaia-report-fav-filter_update-modal-filter-actions_radio {
        display: -webkit-inline-flex;
        display: inline-flex; }
        .gaia-report-fav-filter_update-modal-filter-actions_radio.sz-radio .custom-control-label::before {
          top: 0.15625rem; }
  .gaia-report-fav-filter_update-modal-footer {
    margin-top: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .gaia-report-fav-filter_update-modal-footer .no-default-fav {
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      width: 100%; }
    .gaia-report-fav-filter_update-modal-footer button {
      width: 150px;
      margin-right: 20px; }

.sz-form-group {
  height: 100%; }
  .sz-form-group__input-group-icon {
    z-index: 1 !important; }

.sz-form-group__input-group-icon {
  z-index: 5;
  width: 45px;
  font-size: 1.12rem;
  border: white 0.5px solid;
  background-color: #030e40;
  color: white !important;
  cursor: pointer; }
  .sz-form-group__input-group-icon:focus, .sz-form-group__input-group-icon:active {
    background-color: #030e40;
    color: white !important; }
    .sz-form-group__input-group-icon:focus + .focused, .sz-form-group__input-group-icon:active + .focused {
      background-color: #030e40;
      color: white !important;
      border: 2px solid #239de1;
      border-left: none; }

.sz-form-group__sz-input-text:focus {
  border: 2px solid #239de1; }

.sz-form-group .spinner-border {
  z-index: 5;
  left: 516px;
  top: 2px;
  font-size: 0.12rem; }

.gaia-report-fav-filter_update-modal {
  display: -webkit-flex;
  display: flex; }
  .gaia-report-fav-filter_update-modal-filter {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid #030e40;
    margin-bottom: 10px; }
    .gaia-report-fav-filter_update-modal-filter-title {
      display: -webkit-inline-flex;
      display: inline-flex; }
    .gaia-report-fav-filter_update-modal-filter-actions {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-shrink: 0;
              flex-shrink: 0; }
      .gaia-report-fav-filter_update-modal-filter-actions_checkbox {
        display: -webkit-inline-flex;
        display: inline-flex; }
      .gaia-report-fav-filter_update-modal-filter-actions_radio {
        display: -webkit-inline-flex;
        display: inline-flex; }
        .gaia-report-fav-filter_update-modal-filter-actions_radio.sz-radio .custom-control-label::before {
          top: 0.15625rem; }
  .gaia-report-fav-filter_update-modal-footer {
    margin-top: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .gaia-report-fav-filter_update-modal-footer .no-default-fav {
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      width: 100%; }
    .gaia-report-fav-filter_update-modal-footer button {
      width: 150px;
      margin-right: 20px; }

.report--menu--tabs {
  width: 100%;
  display: -webkit-flex;
  display: flex; }
  .report--menu--tabs .report--menu-body {
    width: 552px; }
    .report--menu--tabs .report--menu-body nav a {
      -webkit-flex-grow: 1;
              flex-grow: 1;
      text-align: center; }
    .report--menu--tabs .report--menu-body .nav.nav-tabs {
      margin-bottom: 0; }
    .report--menu--tabs .report--menu-body .nav-tabs .nav-link {
      border-bottom: 1px solid #dee2e6; }
    .report--menu--tabs .report--menu-body .nav-tabs .nav-link.active {
      color: #009ee0;
      background-color: #ffffff;
      border-color: #dee2e6 #dee2e6 #009ee0;
      border-bottom-width: 3px; }
    .report--menu--tabs .report--menu-body--details-container .gaia-detail-result--header h1 {
      font-size: 1.5rem;
      margin-top: 0.7rem;
      font-family: "DINPro-Regular", sans-serif; }
    .report--menu--tabs .report--menu-body--details-container .gaia-detail-result--header h3 {
      font-weight: 100;
      font-size: 0.7rem;
      font-family: "DINPro-Regular", sans-serif; }
    .report--menu--tabs .report--menu-body tr .cell-width:first-child {
      width: 50%; }
    .report--menu--tabs .report--menu-body .gaia-td-align-right {
      text-align: right; }
    .report--menu--tabs .report--menu-body--details_no-data {
      padding: 10px 10px 0;
      font-size: 1rem; }
  .report--menu--tabs .report--menu_collapsed--body {
    -webkit-writing-mode: vertical-lr;
        -ms-writing-mode: tb-lr;
            writing-mode: vertical-lr;
    -webkit-text-orientation: mixed;
            text-orientation: mixed;
    font-size: 25px;
    width: 78px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .report--menu--tabs .title-disabled {
    color: #6c757d; }
  .report--menu--tabs .report--menu--tabs_list--button {
    background: #009EE0;
    color: white;
    display: -webkit-inline-flex;
    display: inline-flex;
    margin-left: 10px;
    border: 1px solid transparent;
    box-shadow: unset; }
    .report--menu--tabs .report--menu--tabs_list--button.disabled {
      background: #becae675;
      cursor: not-allowed; }
    .report--menu--tabs .report--menu--tabs_list--button.dropdown {
      margin-left: 0; }
    .report--menu--tabs .report--menu--tabs_list--button.dropdown-toggle:hover, .report--menu--tabs .report--menu--tabs_list--button.dropdown-toggle:focus, .report--menu--tabs .report--menu--tabs_list--button.dropdown-toggle:active {
      background-color: #009EE0;
      color: white;
      border-color: transparent; }
    .report--menu--tabs .report--menu--tabs_list--button.dropdown-toggle:after {
      margin-top: 8px; }
  .report--menu--tabs .report--menu--tabs_list--input-container {
    display: -webkit-flex;
    display: flex;
    width: 100%; }
    .report--menu--tabs .report--menu--tabs_list--input-container .sz-form-group {
      width: 100%; }
      .report--menu--tabs .report--menu--tabs_list--input-container .sz-form-group__sz-input-text {
        height: 30px; }
    .report--menu--tabs .report--menu--tabs_list--input-container .sz-icon-line {
      display: -webkit-inline-flex;
      display: inline-flex;
      margin-left: 5px;
      -webkit-align-items: center;
              align-items: center; }
  .report--menu--tabs .hidden {
    display: none !important; }
  .report--menu--tabs_list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    height: 100%; }
    .report--menu--tabs_list--header {
      padding: 17px 10px 5px;
      -webkit-justify-content: space-evenly;
              justify-content: space-evenly;
      border-bottom: 1px solid #cfdbf9b3;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: column;
              flex-flow: column; }
      .report--menu--tabs_list--header_title {
        padding: 0 10px; }
        .report--menu--tabs_list--header_title h2 {
          padding: 10px;
          border: 1px solid #cfdbf9b3;
          font-size: 1.125rem;
          box-shadow: rgba(0, 0, 0, 0.2) 0 0 3px 1px; }
        .report--menu--tabs_list--header_title .listType {
          border: 1px solid #cfdbf9b3 !important;
          font-size: 1.125rem;
          font-family: "DINPro-Bold";
          font-weight: 900;
          line-height: 1.2; }
      .report--menu--tabs_list--header_buttons {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        padding: 5px 10px; }
      .report--menu--tabs_list--header_buttons_noflex {
        padding: 5px 10px; }
    .report--menu--tabs_list--fav {
      background-color: #fff;
      padding: 5px 10px 5px 5px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .report--menu--tabs_list--fav .fav-filters {
        width: 100%; }
      .report--menu--tabs_list--fav .css-pcvwr-control,
      .report--menu--tabs_list--fav .css-u6f56q-control {
        min-height: 40px; }
      .report--menu--tabs_list--fav .fav-filters_cog-wheel {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        justify-items: center; }
      .report--menu--tabs_list--fav--button-container {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
                justify-content: flex-start; }
    .report--menu--tabs_list--filter-sub-label {
      display: -webkit-inline-flex;
      display: inline-flex;
      width: 50%;
      padding-left: 5px;
      color: grey; }
      .report--menu--tabs_list--filter-sub-label_container {
        width: 100%;
        display: -webkit-flex;
        display: flex; }
    .report--menu--tabs_list--filter-label {
      display: -webkit-inline-flex;
      display: inline-flex;
      width: 60%;
      padding-left: 5px; }
      .report--menu--tabs_list--filter-label_container {
        width: 100%;
        display: -webkit-flex;
        display: flex; }
    .report--menu--tabs_list--filter-container {
      padding: 0 15px 10px;
      overflow-y: scroll;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: column;
              flex-flow: column;
      height: 100%; }
      .report--menu--tabs_list--filter-container .sz-form-group__sz-input-text {
        height: 40px; }
      .report--menu--tabs_list--filter-container .css-pcvwr-control,
      .report--menu--tabs_list--filter-container .css-u6f56q-control {
        min-height: 40px; }
      .report--menu--tabs_list--filter-container .pb-0:last-of-type {
        padding-bottom: inherit !important; }
      .report--menu--tabs_list--filter-container_title {
        padding: 5px 10px 0;
        margin-bottom: 0.5rem;
        font-size: 13px; }
      .report--menu--tabs_list--filter-container .react-date-picker__inputGroup {
        font-weight: 100;
        font-family: "DINPro-Regular", sans-serif; }
      .report--menu--tabs_list--filter-container .react-date-picker__button,
      .report--menu--tabs_list--filter-container .react-date-picker .arrow-down-1 {
        padding-top: 5px; }
      .report--menu--tabs_list--filter-container .react-date-picker:last-child > div {
        border-left: 1px solid var(--light); }
        .report--menu--tabs_list--filter-container .react-date-picker:last-child > div:hover {
          border-left: 1px solid #030e40; }
      .report--menu--tabs_list--filter-container .react-date-picker__wrapper {
        border: 1px solid var(--light); }
        .report--menu--tabs_list--filter-container .react-date-picker__wrapper:hover {
          border: 1px solid #030e40; }
        .report--menu--tabs_list--filter-container .react-date-picker__wrapper:before {
          content: "\f120"; }
      .report--menu--tabs_list--filter-container .input_group {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        width: 100%;
        padding: 0.5rem 0; }
        .report--menu--tabs_list--filter-container .input_group .sector,
        .report--menu--tabs_list--filter-container .input_group .city,
        .report--menu--tabs_list--filter-container .input_group .conformity,
        .report--menu--tabs_list--filter-container .input_group .majorFaultType,
        .report--menu--tabs_list--filter-container .input_group .propertyType,
        .report--menu--tabs_list--filter-container .input_group .connectionNumber,
        .report--menu--tabs_list--filter-container .input_group .geoserverBV,
        .report--menu--tabs_list--filter-container .input_group .emOperatingSector,
        .report--menu--tabs_list--filter-container .input_group .networkOperatingSector,
        .report--menu--tabs_list--filter-container .input_group .status,
        .report--menu--tabs_list--filter-container .input_group .zac,
        .report--menu--tabs_list--filter-container .input_group .category,
        .report--menu--tabs_list--filter-container .input_group .origin,
        .report--menu--tabs_list--filter-container .input_group .controlType,
        .report--menu--tabs_list--filter-container .input_group .veConformity,
        .report--menu--tabs_list--filter-container .input_group .vptConformity,
        .report--menu--tabs_list--filter-container .input_group .rate,
        .report--menu--tabs_list--filter-container .input_group .type,
        .report--menu--tabs_list--filter-container .input_group .criteria {
          width: 100%;
          border-color: #030e40 !important; }
        .report--menu--tabs_list--filter-container .input_group .connectionNumber div {
          margin-bottom: 0 !important; }
        .report--menu--tabs_list--filter-container .input_group .visitType {
          width: 100%;
          border-color: #030e40 !important; }
        .report--menu--tabs_list--filter-container .input_group label {
          font-size: 13px;
          text-transform: none !important; }
        .report--menu--tabs_list--filter-container .input_group .react-date-picker {
          height: 42px; }
        .report--menu--tabs_list--filter-container .input_group .sz-checkbox {
          padding: 5px 9px;
          z-index: auto; }
          .report--menu--tabs_list--filter-container .input_group .sz-checkbox label {
            white-space: nowrap; }
        .report--menu--tabs_list--filter-container .input_group .sz-form-group {
          width: 100%; }
          .report--menu--tabs_list--filter-container .input_group .sz-form-group__sz-input-text {
            background-color: transparent !important;
            border: 1px solid #e7e8ee; }
            .report--menu--tabs_list--filter-container .input_group .sz-form-group__sz-input-text:hover, .report--menu--tabs_list--filter-container .input_group .sz-form-group__sz-input-text:focus {
              border-color: #030e40; }
            .report--menu--tabs_list--filter-container .input_group .sz-form-group__sz-input-text::-webkit-input-placeholder {
              font-style: normal;
              font-family: "DINPro-Regular"; }
            .report--menu--tabs_list--filter-container .input_group .sz-form-group__sz-input-text:-ms-input-placeholder {
              font-style: normal;
              font-family: "DINPro-Regular"; }
            .report--menu--tabs_list--filter-container .input_group .sz-form-group__sz-input-text::placeholder {
              font-style: normal;
              font-family: "DINPro-Regular"; }
        .report--menu--tabs_list--filter-container .input_group .form-group {
          display: -webkit-inline-flex;
          display: inline-flex;
          -webkit-align-items: center;
                  align-items: center;
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          margin: 0; }
          .report--menu--tabs_list--filter-container .input_group .form-group label,
          .report--menu--tabs_list--filter-container .input_group .form-group .sz-container-select {
            display: -webkit-inline-flex;
            display: inline-flex;
            margin-bottom: 0; }
          .report--menu--tabs_list--filter-container .input_group .form-group .sz-container-select {
            display: -webkit-inline-flex;
            display: inline-flex;
            -webkit-flex-grow: 1;
                    flex-grow: 1;
            margin-left: 10px;
            margin-bottom: 0 !important;
            padding-top: 0.1rem !important;
            padding-bottom: 0.1rem !important;
            border-color: #030e40 !important; }
            .report--menu--tabs_list--filter-container .input_group .form-group .sz-container-select__chevron {
              top: 8px; }

.map {
  display: -webkit-inline-flex;
  display: inline-flex; }

#viewDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%; }

.hidden {
  display: none; }

#infoDiv {
  position: absolute;
  top: 354px;
  right: 15px; }

#infoDiv input {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px; }

.esri-layer-list__item-toggle .esri-icon-visible {
  color: #239de1; }

.esri-view-height-greater-than-medium .esri-ui-corner .esri-component .esri-expand__content {
  max-height: 999px; }

.esri-view-height-medium .esri-ui-corner .esri-component .esri-expand__content {
  max-height: 650px; }

.esri-view-height-small .esri-ui-corner .esri-component .esri-expand__content {
  max-height: 600px; }

.esri-view-height-xsmall .esri-ui-corner .esri-component .esri-expand__content {
  max-height: 400px; }

.esri-slider--horizontal {
  margin-top: 17px; }
  .esri-slider--horizontal .esri-slider__label {
    top: -27px; }

.esri-view-height-medium .esri-expand .esri-widget--panel,
.esri-view-height-small .esri-expand .esri-widget--panel,
.esri-view-height-xsmall .esri-expand .esri-widget--panel,
.esri-view-height-greater-than-medium .esri-expand .esri-widget--panel {
  max-height: inherit; }

.btn-switch-3d {
  position: absolute !important;
  top: 354px;
  right: 1%;
  margin-right: -4px;
  width: 32px !important;
  height: 32px !important;
  background: white !important; }
  .btn-switch-3d .icon-resize {
    font-size: 26px; }

.btn-map-fullscreen {
  position: absolute !important;
  top: 219px;
  margin-left: 15px;
  width: 32px !important;
  height: 32px !important;
  background: white !important; }

.esri-view-height-medium .esri-expand .esri-widget--panel,
.esri-view-height-small .esri-expand .esri-widget--panel,
.esri-view-height-xsmall .esri-expand .esri-widget--panel,
.esri-view-height-greater-than-medium .esri-expand .esri-widget--panel {
  width: 550px; }

.network-table--pagination {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 25px;
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.network-table {
  width: 100%;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .network-table--element_count {
    padding-bottom: 10px; }
  .network-table .react-bootstrap-table {
    overflow: scroll; }
    .network-table .react-bootstrap-table th.sortable {
      cursor: pointer; }
  .network-table i:hover {
    cursor: pointer; }
  .network-table .selected {
    font-weight: bold;
    color: #009ee0; }
  .network-table table.sz-table {
    margin: 0; }
    .network-table table.sz-table thead tr.bg-light-blue th {
      font-weight: bold; }
      .network-table table.sz-table thead tr.bg-light-blue th:first-child {
        border-left-width: 0; }
        .network-table table.sz-table thead tr.bg-light-blue th:first-child::after {
          width: 0; }
      .network-table table.sz-table thead tr.bg-light-blue th:last-child {
        border-right-width: 0; }
    .network-table table.sz-table td {
      border-color: white;
      border-style: solid;
      border-width: 0 1px 1px;
      white-space: nowrap !important; }
      .network-table table.sz-table td:first-child {
        border-left-width: 0; }
        .network-table table.sz-table td:first-child::after {
          background: #030e40; }
      .network-table table.sz-table td:last-child {
        border-right-width: 0; }
      .network-table table.sz-table td i.mdi-check,
      .network-table table.sz-table td i.mdi-toggle-switch {
        color: #1c7430; }
      .network-table table.sz-table td i.mdi-close {
        color: red; }

.btn-fullscreen {
  position: absolute !important;
  margin-top: 15px;
  margin-left: 19px;
  width: 32px !important;
  height: 32px !important;
  background: white !important; }

.btn-logout {
  position: absolute !important;
  margin-top: 55px;
  margin-left: 19px;
  width: 32px !important;
  height: 32px !important;
  background: #ff0000 !important; }

.sz-btn {
  width: auto; }

.report {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  box-shadow: -2px -2px 1px -1px rgba(0, 0, 0, 0.2); }
  .report .map {
    width: 100%;
    height: 100%; }
    .report .map #infoDiv {
      position: absolute;
      top: 420px;
      right: 15px; }
  .report .map3d {
    width: 100%;
    height: 100%; }
  .report .smartshape {
    width: 100%;
    height: 100%; }
  .report .display-container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .report--menu_container {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    background: white;
    height: 100%;
    box-shadow: -2px -2px 1px -1px rgba(0, 0, 0, 0.2); }
  .report--body {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
    -webkit-flex-flow: column;
            flex-flow: column; }
    .report--body .nav.nav-tabs {
      margin: 17px 0 5px;
      padding: 0 20px; }
      .report--body .nav.nav-tabs .nav-link.active,
      .report--body .nav.nav-tabs .nav-link:hover {
        background-color: transparent;
        color: #239de1;
        border-bottom: 2px solid #239de1; }
      .report--body .nav.nav-tabs .nav-item {
        -webkit-flex-grow: 1;
                flex-grow: 1;
        text-align: center; }
    .report--body_container {
      overflow: hidden;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-flex-grow: 1;
              flex-grow: 1; }
      .report--body_container .report-table__networkID.clickable {
        cursor: pointer;
        text-decoration: underline; }
        .report--body_container .report-table__networkID.clickable:hover {
          text-decoration: none;
          color: #239de1; }
    .report--body_table_container {
      overflow: hidden;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      padding: 15px 20px 0; }
      .report--body_table_container .report-table__networkID.clickable {
        cursor: pointer;
        text-decoration: underline; }
        .report--body_table_container .report-table__networkID.clickable:hover {
          text-decoration: none;
          color: #239de1; }
    .report--body_map_container {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-flex-grow: 1;
              flex-grow: 1; }
  .report .report-table th,
  .report .sz-table th {
    min-width: 120px; }

.search-suggestions {
  position: absolute;
  left: 0;
  top: 45px;
  width: 560px;
  background-color: #fff;
  border: solid #030e40;
  border-width: 1px 2px 2px 1px;
  z-index: 20; }
  .search-suggestions-header {
    background-color: #cfdbf9b3;
    font-size: 1rem;
    font-weight: bold;
    padding: 5px;
    color: #030f40; }
  .search-suggestions-loader {
    padding: 0 10px; }
  .search-suggestions-item {
    display: -webkit-flex;
    display: flex;
    color: #829ED2;
    cursor: pointer; }
    .search-suggestions-item-container {
      padding: 8px 3px;
      margin: 0 5px;
      width: 100%;
      border-top: 1px solid #829ED2; }
    .search-suggestions-item:hover {
      background-color: #cfdbf9b3; }
    .search-suggestions-item .suggestion-icon {
      font-size: 1.2rem;
      margin-right: 20px; }

.gaia-search {
  width: 558px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch;
  height: 100%;
  margin-bottom: 0; }

.sz-form-group {
  height: 100%; }
  .sz-form-group__input-group-icon {
    z-index: 1 !important;
    height: 36px; }

.gaia-search__input {
  height: 36px;
  width: 513px; }
  .gaia-search__input-clear {
    cursor: pointer;
    z-index: 5;
    right: 45px;
    height: 100%;
    width: 26px;
    font-size: 1.12rem;
    border-right: #AFB8CE 0.5px solid; }

.sz-form-group__input-group-icon {
  z-index: 5;
  width: 45px;
  font-size: 1.12rem;
  border: white 0.5px solid;
  background-color: #CDD2E0;
  color: white !important;
  cursor: pointer; }
  .sz-form-group__input-group-icon:focus, .sz-form-group__input-group-icon:active {
    background-color: #CDD2E0;
    color: white !important; }
    .sz-form-group__input-group-icon:focus + .focused, .sz-form-group__input-group-icon:active + .focused {
      background-color: #CDD2E0;
      color: white !important;
      border: 2px solid #239de1;
      border-left: none; }

.sz-form-group__sz-input-text:focus {
  border: 2px solid #239de1; }

.sz-form-group .spinner-border {
  z-index: 5;
  left: 516px;
  top: 2px;
  font-size: 0.12rem; }

.gaia-suggestions {
  position: absolute;
  top: 40px;
  z-index: 10;
  color: #072B4E;
  background: #AFB8CE 0% 0% no-repeat padding-box;
  opacity: 1;
  font-family: 'DINPro';
  width: 29%;
  padding-left: 0; }
  .gaia-suggestions_hidden {
    visibility: hidden; }
  .gaia-suggestions li {
    list-style-type: none;
    -webkit-border-after: #030e40 solid 0.5px;
            border-block-end: #030e40 solid 0.5px;
    font-size: 17px;
    font-style: italic;
    padding-left: 1rem;
    line-height: 2rem;
    letter-spacing: 0;
    font-weight: lighter; }
    .gaia-suggestions li:hover, .gaia-suggestions li.suggestion-active {
      background-color: #030e40;
      opacity: 1;
      color: white;
      cursor: pointer;
      font-weight: 700; }
  .gaia-suggestions li:last-child {
    -webkit-border-after: none;
            border-block-end: none; }

.no-suggestions {
  color: #999;
  padding: 0.5rem; }

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999; }

.gaia-header {
  height: 45px; }
  .gaia-header h1.page-title {
    font-size: 1.5rem;
    margin-bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
            align-self: center;
    margin-left: 20px;
    font-family: "DINPro-Regular", sans-serif; }
  .gaia-header .sz-icon-bold.arrow-down-1 {
    display: none; }
  .gaia-header .sz-dropdown .dropdown-menu:after, .gaia-header .sz-dropdown .dropdown-menu:before {
    left: 93%; }
  .gaia-header .dropdown-menu.show {
    -webkit-transform: translate(-300px, 44px) !important;
            transform: translate(-300px, 44px) !important;
    width: 352px; }
  .gaia-header.unlogged {
    padding-right: 20rem; }
  .gaia-header__brand {
    padding: 2px 0;
    padding-left: 0.5rem !important; }
  .gaia-header__menu-icon {
    font-size: 2rem;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-right: 20px;
    cursor: pointer;
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 50px;
    transition: width 0.5s ease; }
    .gaia-header__menu-icon__text {
      display: block;
      position: absolute;
      left: 60px;
      font-size: 1.5rem;
      line-height: 2.8rem;
      transition: 0.25s ease; }
    .gaia-header__menu-icon span {
      background: #030e40;
      display: block;
      height: 4px;
      left: 7px;
      top: 18px;
      opacity: 1;
      position: absolute;
      transition: 0.25s ease-in-out;
      width: 12px; }
    .gaia-header__menu-icon.menuIsOpen {
      width: 200px; }
      .gaia-header__menu-icon.menuIsOpen .gaia-header__menu-icon__text {
        opacity: 1; }
      .gaia-header__menu-icon.menuIsOpen span:nth-child(1) {
        left: 16px;
        top: 15px; }
      .gaia-header__menu-icon.menuIsOpen span:nth-child(2) {
        left: 23px;
        top: 15px; }
      .gaia-header__menu-icon.menuIsOpen span:nth-child(3) {
        left: -20px;
        opacity: 0; }
      .gaia-header__menu-icon.menuIsOpen span:nth-child(4) {
        left: 50px;
        opacity: 0; }
      .gaia-header__menu-icon.menuIsOpen span:nth-child(5) {
        left: 16px;
        top: 22px; }
      .gaia-header__menu-icon.menuIsOpen span:nth-child(6) {
        left: 23px;
        top: 22px; }
      .gaia-header__menu-icon.menuIsOpen span:nth-child(2),
      .gaia-header__menu-icon.menuIsOpen span:nth-child(5) {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
      .gaia-header__menu-icon.menuIsOpen span:nth-child(1),
      .gaia-header__menu-icon.menuIsOpen span:nth-child(6) {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
    .gaia-header__menu-icon.menuIsClose .gaia-header__menu-icon__text {
      opacity: 0; }
      .gaia-header__menu-icon.menuIsClose .gaia-header__menu-icon__text p {
        margin-bottom: 0; }
    .gaia-header__menu-icon.menuIsClose span:nth-child(1),
    .gaia-header__menu-icon.menuIsClose span:nth-child(2) {
      top: 10px; }
    .gaia-header__menu-icon.menuIsClose span:nth-child(3),
    .gaia-header__menu-icon.menuIsClose span:nth-child(4) {
      top: 18px; }
    .gaia-header__menu-icon.menuIsClose span:nth-child(5),
    .gaia-header__menu-icon.menuIsClose span:nth-child(6) {
      top: 26px; }
    .gaia-header__menu-icon.menuIsClose span:nth-child(2n) {
      left: 24px; }
    .gaia-header__menu-icon.menuIsClose span:nth-child(2n + 1) {
      left: 12px; }
  .gaia-header__search-form {
    padding: 4px;
    margin: 0 auto; }
  .gaia-header__app-icon {
    font-size: 1.7rem; }
  .gaia-header__notif-icon {
    font-size: 2rem; }
  .gaia-header__signin {
    font-size: 1.8rem; }
  .gaia-header__signin-text {
    font-size: 1rem; }
  .gaia-header__brand-title {
    font-size: 1.3rem;
    padding-top: 11px;
    padding-right: 10rem !important; }

.gaia-sub-header {
  height: 52px; }

.bg-fade {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.5); }

.lds-grid {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin-top: 20%;
  margin-left: 50%; }

.lds-grid div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #030e40;
  -webkit-animation: lds-grid 1.2s linear infinite;
          animation: lds-grid 1.2s linear infinite; }

.lds-grid div:nth-child(1) {
  top: 6px;
  left: 6px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }

.lds-grid div:nth-child(2) {
  top: 6px;
  left: 26px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }

.lds-grid div:nth-child(3) {
  top: 6px;
  left: 45px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.lds-grid div:nth-child(4) {
  top: 26px;
  left: 6px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }

.lds-grid div:nth-child(5) {
  top: 26px;
  left: 26px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.lds-grid div:nth-child(6) {
  top: 26px;
  left: 45px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s; }

.lds-grid div:nth-child(7) {
  top: 45px;
  left: 6px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.lds-grid div:nth-child(8) {
  top: 45px;
  left: 26px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s; }

.lds-grid div:nth-child(9) {
  top: 45px;
  left: 45px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s; }

@-webkit-keyframes lds-grid {
  0%, 100% {
    opacity: 1; }
  50% {
    opacity: 0.5; } }

@keyframes lds-grid {
  0%, 100% {
    opacity: 1; }
  50% {
    opacity: 0.5; } }

.gaia-container {
  height: calc(100% - 60px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap; }
  .gaia-container.connected {
    height: 100%;
    width: 100%;
    transition: 0.5s ease-in; }
  .gaia-container.isOpen {
    transition: 0.3s ease; }

.gaia-aside {
  height: calc(100% - 45px); }
  .gaia-aside.sz-aside.sz-aside-enter-done {
    width: 100%; }
    .gaia-aside.sz-aside.sz-aside-enter-done .sz-aside__item--title {
      opacity: 1; }
  @media screen and (min-width: 768px) {
    .gaia-aside.sz-aside.sz-aside-enter-done {
      width: 200px; } }
  .gaia-aside nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 100%; }
    .gaia-aside nav ul {
      overflow: hidden; }
    .gaia-aside nav .gaia-vision--version-container {
      display: -webkit-flex;
      display: flex;
      -webkit-align-self: flex-end;
              align-self: flex-end;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      padding-bottom: 15px; }

.gaia-main-container {
  display: -webkit-flex;
  display: flex;
  height: calc(100% - 45px); }

.no-padding {
  padding: 0; }

.clickable {
  cursor: pointer !important; }

.container-content {
  width: 100%; }

.profil {
  width: 100%;
  background-color: white; }
  .profil .profil-container {
    padding: 25px 50px;
    box-shadow: -2px -2px 1px -1px rgba(0, 0, 0, 0.2);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow: hidden;
    min-height: 1000px; }
  .profil .profil-info-container {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: baseline;
            align-items: baseline;
    border: 2px solid var(--toolbar);
    padding: 1rem; }
    .profil .profil-info-container .user-info {
      display: list-item;
      width: 60%;
      list-style-type: none; }
      .profil .profil-info-container .user-info .name {
        font-weight: bold;
        font-size: 1rem; }
    .profil .profil-info-container .buttons {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: baseline;
              align-items: baseline;
      width: 40%;
      height: 80%; }
  .profil .profil-form-container {
    padding-top: 1rem;
    width: 50%; }
  .profil button {
    background: white;
    border: 2px solid rgba(0, 0, 0, 0.2) !important;
    margin-left: 5px !important; }
  .profil .sz-btn:active, .profil .sz-btn:hover, .profil .sz-btn:visited, .profil .sz-btn:focus {
    background: rgba(0, 0, 0, 0.2) !important;
    background-color: rgba(0, 0, 0, 0.2) !important; }
  .profil .nav.nav-tabs .nav-link.active {
    border: 0px;
    border-bottom: 2px solid #239de1;
    color: #239de1;
    background-color: white; }
  .profil .item-links-icon {
    display: -webkit-flex;
    display: flex;
    padding-left: 13px;
    font-size: 28px;
    position: relative;
    top: 10px; }

.history {
  width: 100%;
  background-color: white; }
  .history .history-container {
    padding: 25px 50px;
    box-shadow: -2px -2px 1px -1px rgba(0, 0, 0, 0.2);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow: hidden; }
  .history .history-search {
    height: unset;
    width: 513px;
    display: -webkit-flex;
    display: flex; }
    .history .history-search .sz-form-group__sz-input-text {
      height: 40px; }
  .history .react-bootstrap-table {
    overflow: scroll; }
  .history-table {
    width: 100%;
    border: 1px solid #becae675; }
    .history-table .sz-table-header, .history-table tbody > tr {
      display: -webkit-flex;
      display: flex; }
      .history-table .sz-table-header th, .history-table .sz-table-header td, .history-table tbody > tr th, .history-table tbody > tr td {
        display: -webkit-inline-flex;
        display: inline-flex; }
        .history-table .sz-table-header th:first-child, .history-table .sz-table-header td:first-child, .history-table tbody > tr th:first-child, .history-table tbody > tr td:first-child {
          -webkit-flex-grow: 1;
                  flex-grow: 1; }
          .history-table .sz-table-header th:first-child::after, .history-table .sz-table-header td:first-child::after, .history-table tbody > tr th:first-child::after, .history-table tbody > tr td:first-child::after {
            width: 0; }
        .history-table .sz-table-header th:last-child, .history-table .sz-table-header td:last-child, .history-table tbody > tr th:last-child, .history-table tbody > tr td:last-child {
          width: 300px;
          -webkit-flex-shrink: 1;
                  flex-shrink: 1; }
    .history-table tbody > tr {
      cursor: pointer; }
      .history-table tbody > tr:hover {
        background-color: #cfdbf9b3; }
    .history-table .sz-table-header th {
      font-size: 1.2rem;
      padding: 15px 0.75rem; }

.gaia_breadcrumbs {
  display: -webkit-flex;
  display: flex;
  font-size: 0.8125rem;
  padding: 5px;
  border-bottom: 1px solid #cfdbf9b3;
  background-color: #dff9fb; }
  .gaia_breadcrumbs .gaia_breadcrumb {
    display: -webkit-inline-flex;
    display: inline-flex; }
    .gaia_breadcrumbs .gaia_breadcrumb.clickable:hover {
      color: #239de1;
      -webkit-text-decoration: underline #239de1;
              text-decoration: underline #239de1; }
    .gaia_breadcrumbs .gaia_breadcrumb--separator {
      width: 25px;
      font-size: 15px;
      text-align: center;
      height: 26px; }
  .gaia_breadcrumbs .gaia_breadcrumbs-search_return {
    float: left;
    display: -webkit-inline-flex;
    display: inline-flex; }
  .gaia_breadcrumbs .breadcrumb {
    margin: 0;
    padding: 0;
    background-color: transparent;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap; }
    .gaia_breadcrumbs .breadcrumb .clickable a:hover {
      color: #239de1;
      text-decoration: none; }
    .gaia_breadcrumbs .breadcrumb .estate_object_name a:hover {
      text-decoration: none;
      cursor: auto;
      cursor: initial; }

.gaia-search-data-count {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  height: 80px;
  background: #FFFFFF;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #cfdbf9b3;
  padding-left: 10px; }

.gaia-control-buttons {
  border-bottom: 1px solid #cfdbf9b3;
  padding-top: 5px;
  margin-left: 0;
  margin-right: 0;
  background: #FFFFFF;
  width: 100%;
  height: 80px;
  -webkit-flex-flow: column;
          flex-flow: column; }
  .gaia-control-buttons .gaia-control-button {
    padding: 0.300rem 0.75rem;
    background: #009EE0;
    color: white;
    display: -webkit-inline-flex;
    display: inline-flex;
    border: 1px solid transparent;
    box-shadow: unset;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center; }
    .gaia-control-buttons .gaia-control-button.disabled {
      background: #AFB8CE; }
      .gaia-control-buttons .gaia-control-button.disabled:hover {
        cursor: not-allowed; }

.gaia-search-filters {
  padding: 0 12px;
  max-width: 100%; }
  @media (max-width: 1400px) {
    .gaia-search-filters {
      max-height: 600px;
      margin-bottom: 10px;
      overflow: scroll; } }
  .gaia-search-filters__indice .sz-card-collapse__header {
    padding-left: 15px !important; }
    .gaia-search-filters__indice .sz-card-collapse__header .w-100 {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
  .gaia-search-filters__category, .gaia-search-filters__no-subcategories {
    color: #ffffff; }
    .gaia-search-filters__category .sz-card-collapse, .gaia-search-filters__no-subcategories .sz-card-collapse {
      margin-top: 2px;
      margin-bottom: 0 !important; }
      .gaia-search-filters__category .sz-card-collapse .sz-card-collapse__header, .gaia-search-filters__no-subcategories .sz-card-collapse .sz-card-collapse__header {
        padding: 1rem; }
    .gaia-search-filters__category .gaia-search-filters__category-title, .gaia-search-filters__no-subcategories .gaia-search-filters__category-title {
      opacity: 1;
      color: #ffffff; }
      @media (max-width: 1400px) {
        .gaia-search-filters__category .gaia-search-filters__category-title, .gaia-search-filters__no-subcategories .gaia-search-filters__category-title {
          font-size: 12px; } }
    .gaia-search-filters__category .sz-left-border::before,
    .gaia-search-filters__category .sz-card-collapse__header::after, .gaia-search-filters__no-subcategories .sz-left-border::before,
    .gaia-search-filters__no-subcategories .sz-card-collapse__header::after {
      background: none; }
    .gaia-search-filters__category .sz-card-collapse__body::before,
    .gaia-search-filters__category .sz-card-collapse__footer::before, .gaia-search-filters__no-subcategories .sz-card-collapse__body::before,
    .gaia-search-filters__no-subcategories .sz-card-collapse__footer::before {
      background: none; }
    .gaia-search-filters__category .pl-4, .gaia-search-filters__no-subcategories .pl-4 {
      padding-left: 0 !important; }
    .gaia-search-filters__category .p-3, .gaia-search-filters__no-subcategories .p-3 {
      padding: 0.7rem !important; }
    .gaia-search-filters__category .border-left, .gaia-search-filters__no-subcategories .border-left {
      border-left: none !important; }
    .gaia-search-filters__category li, .gaia-search-filters__no-subcategories li {
      list-style-type: none;
      margin: 7px 0; }
  .gaia-search-filters__category .sz-card-collapse .sz-card-collapse__header {
    background: #072b4e 0% 0% no-repeat padding-box !important; }
    .gaia-search-filters__category .sz-card-collapse .sz-card-collapse__header .bg-white {
      background-color: unset !important; }
  .gaia-search-filters__no-subcategories .sz-card-collapse .sz-card-collapse__header {
    background: #afb8ce 0% 0% no-repeat padding-box !important; }
    .gaia-search-filters__no-subcategories .sz-card-collapse .sz-card-collapse__header .bg-white {
      background-color: unset !important; }
  .gaia-search-filters__subcategory {
    font-size: 12px;
    background: #ffff;
    margin: 0.5rem 0;
    width: 100%; }
    .gaia-search-filters__subcategory-item-title {
      margin-right: 5px; }
    .gaia-search-filters__subcategory-item-count {
      font-weight: bold; }
  .gaia-search-filters::after {
    content: "";
    border: solid #072b4e;
    position: absolute;
    border-width: 100px 0.5px;
    right: 0;
    top: 0;
    height: -webkit-fill-available; }

.filter-spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
  height: 70%; }
  .filter-spinner .spinner-border {
    position: absolute;
    top: 20%; }

.gaia-search-filters__category .sz-card-collapse .sz-card-collapse__header,
.gaia-search-filters__no-subcategories .sz-card-collapse .sz-card-collapse__header {
  padding: 0.4rem; }
  .gaia-search-filters__category .sz-card-collapse .sz-card-collapse__header .w-100,
  .gaia-search-filters__no-subcategories .sz-card-collapse .sz-card-collapse__header .w-100 {
    padding-top: 0.5rem; }

.gaia-search-filters__category .custom-control-label::before,
.gaia-search-filters__no-subcategories .custom-control-label::before {
  border-color: #239de1; }

.gaia-search-filters__category .custom-control-input:checked ~ .custom-control-label::before,
.gaia-search-filters__no-subcategories .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #239de1;
  background: #239de1; }

.gaia-filter-side {
  overflow-y: auto;
  height: calc(100% - 80px);
  position: absolute;
  width: 100%;
  border-right: 1px solid #cfdbf9b3; }

.radio-filter .custom-control-label::before {
  border-radius: 50% !important; }

.reset-button {
  font-size: 1rem;
  color: var(--gray-light); }

.reset-button.active {
  cursor: pointer;
  color: #239ee0; }

.report-menu-tabs-list-option-container {
  display: -webkit-inline-flex;
  display: inline-flex;
  width: 100%; }
  .report-menu-tabs-list-option-container .color-bar {
    width: 7%;
    margin: 3px 12px; }
    .report-menu-tabs-list-option-container .color-bar.green {
      background-color: green; }
    .report-menu-tabs-list-option-container .color-bar.yellow {
      background-color: yellow; }
    .report-menu-tabs-list-option-container .color-bar.red {
      background-color: red; }
    .report-menu-tabs-list-option-container .color-bar.orange {
      background-color: orange; }
    .report-menu-tabs-list-option-container .color-bar.grey {
      background-color: grey; }
    .report-menu-tabs-list-option-container .color-bar.blue {
      background-color: blue; }
    .report-menu-tabs-list-option-container .color-bar.black {
      background-color: black; }
    .report-menu-tabs-list-option-container .color-bar.line {
      background-color: #00c300; }
    .report-menu-tabs-list-option-container .color-bar.squash-blossom {
      background-color: #ffd369; }
    .report-menu-tabs-list-option-container .color-bar.good-samaritan {
      background-color: #3f6782; }
  .report-menu-tabs-list-option-container .option-text {
    width: 80%; }

.gaia-search-result {
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  display: -webkit-flex;
  display: flex;
  box-shadow: -2px -2px 1px -1px rgba(0, 0, 0, 0.2); }
  .gaia-search-result-container {
    display: -webkit-inline-flex;
    display: inline-flex; }
  .gaia-search-result .sz-aside.sz-aside-exit-done {
    width: 78px; }
  .gaia-search-result .sz-aside__list {
    padding-right: 15px;
    padding-left: 15px; }
    .gaia-search-result .sz-aside__list li,
    .gaia-search-result .sz-aside__list a {
      padding: 17px 0; }
  .gaia-search-result .gaia-search-category {
    color: #239ee0; }
    .gaia-search-result .gaia-search-category__item-title {
      font-size: 11px; }
    .gaia-search-result .gaia-search-category a {
      text-align: center; }
    .gaia-search-result .gaia-search-category .active {
      background: none;
      color: inherit;
      -webkit-filter: brightness(1);
              filter: brightness(1); }
      .gaia-search-result .gaia-search-category .active .gaia-search-category__item-icon {
        color: inherit; }
      .gaia-search-result .gaia-search-category .active::after {
        content: "";
        border: solid #239ee0;
        position: absolute;
        bottom: 1rem;
        border-width: 48px 2px;
        right: 0;
        left: 58px;
        -webkit-filter: brightness(1);
                filter: brightness(1); }
  .gaia-search-result .gaia-search-data {
    overflow-y: auto;
    height: calc(100% - 80px);
    padding-left: 0;
    margin-bottom: 0; }
    .gaia-search-result .gaia-search-data__item {
      opacity: 1;
      list-style-type: none;
      padding: 15px 2%;
      letter-spacing: 0;
      color: #072B4E;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      .gaia-search-result .gaia-search-data__item.active {
        font-weight: bold;
        padding: 20px 2%;
        color: #009EE0; }
      .gaia-search-result .gaia-search-data__item-content {
        text-align: left;
        width: calc(100% - 90px);
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        word-break: break-word; }
        @media screen and (max-width: 1200px) {
          .gaia-search-result .gaia-search-data__item-content {
            width: calc(100% - 55px); } }
      .gaia-search-result .gaia-search-data__item-content-title {
        display: -webkit-flex;
        display: flex; }
      .gaia-search-result .gaia-search-data__item-content-more {
        font-size: 11px;
        display: -webkit-flex;
        display: flex; }
      .gaia-search-result .gaia-search-data__item-links {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-shrink: 1;
                flex-shrink: 1;
        -webkit-justify-content: space-around;
                justify-content: space-around; }
        @media screen and (max-width: 1200px) {
          .gaia-search-result .gaia-search-data__item-links {
            -webkit-flex-direction: column;
                    flex-direction: column; } }
        .gaia-search-result .gaia-search-data__item-links .gaia-search-data__item-links-icon {
          display: -webkit-flex;
          display: flex;
          padding-right: 7px;
          cursor: pointer; }
          .gaia-search-result .gaia-search-data__item-links .gaia-search-data__item-links-icon.empty {
            width: 21px; }
        .gaia-search-result .gaia-search-data__item-links span {
          margin-left: 5px; }
      .gaia-search-result .gaia-search-data__item-type {
        padding-left: 5px;
        width: 20px;
        display: block;
        -webkit-flex-shrink: 1;
                flex-shrink: 1;
        -webkit-justify-content: space-around;
                justify-content: space-around; }
        @media screen and (max-width: 1200px) {
          .gaia-search-result .gaia-search-data__item-type {
            -webkit-flex-direction: column;
                    flex-direction: column; } }
        .gaia-search-result .gaia-search-data__item-type .gaia-search-data__item-links-icon {
          display: -webkit-flex;
          display: flex;
          font-size: 1.2rem; }
        .gaia-search-result .gaia-search-data__item-type span {
          margin-left: 5px; }
    .gaia-search-result .gaia-search-data__count {
      padding: 10px; }
  .gaia-search-result .category-tab {
    height: 100%; }
    .gaia-search-result .category-tab.active {
      background: none;
      max-width: 550px;
      width: 550px; }
  .gaia-search-result .category-filters {
    height: 100%; }

.gaia-search__expand-or-collapse {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  padding-bottom: 35px; }

.button-invisible {
  background-color: transparent;
  border: none; }
  .button-invisible:active, .button-invisible:focus {
    outline: none; }

.gaia-search__aside {
  width: 78px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
  border-right: 1px solid #cfdbf9b3; }
  .gaia-search__aside__item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    justify-items: center;
    justify-self: center; }

.gaia-search__main-tab-result {
  margin: 0; }

.mdi-transparent-icon {
  width: 24px;
  height: 24px;
  background: transparent; }

.background-loading {
  background-image: url(/static/media/bg-loading.874d40e7.png);
  background-color: #0000428F;
  width: 100%;
  height: calc(100% + 60px); }
  .background-loading img {
    width: 200px; }

.map {
  width: 100%;
  height: 100%; }

.map3d {
  width: 100%;
  height: 100%; }

.smartshape {
  width: 100%;
  height: 100%; }

.display-container {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.hidden {
  display: none; }

@font-face {
  font-family: "gaia-icons";
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/icomoon.woff) format("woff"); }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(/fonts/MaterialIcons-Regular.woff2) format("woff2"), url(/fonts/MaterialIcons-Regular.woff) format("woff"), url(/fonts/MaterialIcons-Regular.ttf) format("truetype"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .material-icons.material-icons-24px {
    font-size: 24px; }
  .material-icons.material-icons-36px {
    font-size: 36px; }

.esri-icon-visible {
  font-size: 25px;
  font-family: "gaia-icons" !important; }

.esri-icon-non-visible {
  font-size: 25px;
  font-family: "gaia-icons" !important; }

.gaia-icons {
  display: block;
  font-size: 45px;
  font-family: "gaia-icons" !important; }
  .gaia-icons.gaia-icon-file::before {
    content: "\e900"; }
  .gaia-icons.gaia-icon-file.active::before {
    content: "\e903"; }
  .gaia-icons.gaia-icon-pipeline::before {
    content: "\e901"; }
  .gaia-icons.gaia-icon-pipeline.active::before {
    content: "\e905"; }
  .gaia-icons.gaia-icon-map-marker::before {
    content: "\e902"; }
  .gaia-icons.gaia-icon-map-marker.active::before {
    content: "\e904"; }

html, body, #gaia-app {
  height: 100%;
  background-color: #EAEDF4;
  font-family: 'DINPro-Regular'; }

.gaia-content-container {
  max-width: 1100px; }

.text-secondary {
  color: #009EE0 !important; }

.sz-aside__item.active, .sz-aside__item.active:hover, .sz-aside__item.active:visited {
  color: #009EE0;
  background: none; }

