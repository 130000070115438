@import "../../styles/_colors";

.gaia-detail-result {
    width: 100%;
    height: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    -webkit-box-shadow: -2px -2px 5px -1px rgba(0, 0, 0, 0.76);
    -moz-box-shadow: -2px -2px 5px -1px rgba(0, 0, 0, 0.76);
    box-shadow: -2px -2px 5px -1px rgba(0, 0, 0, 0.76);
    display: flex;

    &-container {
        display: inline-flex;
    }

    &--header {
        display: flex;
        &--primary-title,
        &--secondary-title {
            margin-left: 10px;
        }
    }

    &--nav {
        height: 57px;
        border-top: 1px solid $fourth-lighter;

        .nav-link {
            padding: 0.5rem 0.5rem;
            &.active,
            &:hover {
                color: $secondary;
                background: inherit;
                border-bottom: 4px solid $secondary;
            }
        }
    }

    .tab-content {
        // 137 = heights of gaia-detail-result--header + gaia-detail-result--nav
        height: calc(100% - 204px);
        overflow-y: scroll;
    }

    .gaia-detail-result--expanded-body {
        width: 562px;

        table td,
        table th {
            border: none;
        }
    }

    .item-header {
        width: 93%;
    }
    .item-type {
        font-size: 1.5rem;
        i {
            float: right;
            padding-right: 8px;
            padding-top: 9px;
        }
    }

    p {
        margin-bottom: 0.5rem;
    }

    tr .cell-width {
        &:first-child {
            width: 50%;
        }
    }

    .gaia-td-align-left {
        text-align: left;
    }

    .gaia-td-align-right {
        text-align: right;
    }

    .secondary-title {
        font-weight: 100;
        font-size: 0.7rem;
        font-family: "DINPro-Regular", sans-serif;
    }

    .primary-title {
        font-size: 1.5rem;
        margin-top: 0.7rem;
        font-family: "DINPro-Regular", sans-serif;
    }

    .primary-title-category {
        font-size: 1.2rem;
        margin-top: 0.7rem;
        font-family: "DINPro-Regular", sans-serif;
        color: #239de1;
    }

    .container-bordered {
        .card-header {
            border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
        }
    }

    .accordion {
        .card {
            border: none !important;
        }
    }

    .gaia-detail-result--collapsed-body {
        width: 78px;
        overflow-y: scroll;
        display: flex;
        justify-content: center;
        align-items: center;
        writing-mode: vertical-lr;
        text-orientation: mixed;
        cursor: pointer;

        .gaia-detail-result--collapsed-body--title {
            font-size: 25px;
        }
    }

    .mdi.visit-ok {
        color: #00ff00;
    }

    .mdi.visit-ko {
        color: #ff0000;
    }

    &--documents-container {
        padding: 15px 10px;

        &--doc {
            display: flex;
            flex-flow: column;
            cursor: pointer;

            &-title {
                word-break: break-word;
                font-size: 12px;
            }

            &-icon {
                max-width: 50%;
                align-self: center;
                margin: 20px 0 2px;
            }
        }
    }
}
.report--menu-body--details-container {
    height: 100%;
    .tab-content {
        // 137 = heights of gaia-detail-result--header + gaia-detail-result--nav
        height: calc(100% - 204px);
        overflow-y: scroll;
        .container-bordered {
            .card-header {
                border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
                border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
            }
        }

        .accordion {
            .card {
                border: none !important;
            }
        }
    }
}

.thematicMap--menu-body--details-container {
    height: 100%;
    .tab-content {
        // 137 = heights of gaia-detail-result--header + gaia-detail-result--nav
        height: calc(100% - 204px);
        overflow-y: scroll;
    }
}

.table-overflow {
    overflow: auto;
    display: block;
    table-layout: auto;
}

.card-header {
    i {
        float: right;
        font-size: 1rem;
    }
}

.work3d .container-bordered .card-header {
    border-top: none !important;
    border-bottom: none !important;
    background-color: white;
}

.project {
    height: 100%;
    .project-header {
        display: block !important;
    }
}

.signal {
    height: 100%;
    .signal-header {
        display: block !important;
    }
}

.siteIndustriel {
    height: 100%;

    .siteIndustriel-header {
        display: block !important;
    }
}

.work3d {
    .gaia-search-data__item {
        opacity: 1;
        list-style-type: none;
        padding: 15px 2%;
        letter-spacing: 0;
        color: #072b4e;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .gaia-search-data__item.active {
        font-weight: bold;
        padding: 20px 2%;
        color: #009ee0;
    }
    .gaia-search-data__item-links {
        width: 10%;
    }
    .gaia-search-data__item-content {
        width: 80%;
    }
    .card-header {
        i {
            float: left;
            font-size: 0.5rem;
            padding-top: 8px;
            padding-right: 16px;
        }
    }
    .gaia_breadcrumbs {
        background-color: white;
    }
}
.btn-light {
    background: linear-gradient(to left, #d1d2ce, var(--light)) repeat-x;
    border-color: var(--light);
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
    float: right;
}
.btn-light:hover {

    background: linear-gradient(to left, #dff9fb, var(--light)) repeat-x;
    border-color: var(--light);
}

