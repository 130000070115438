@import "src/styles/_colors.scss";

.network-table {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &--element_count {
    padding-bottom: 10px;
  }
  .react-bootstrap-table {
    overflow: scroll;
    th.sortable {
      cursor: pointer;
    }
  }

  i:hover {
    cursor: pointer;
  }

  .selected {
    font-weight: bold;
    color: #009ee0;
  }

  table.sz-table {
    margin: 0;

    thead {
      tr.bg-light-blue {
        th {
          font-weight: bold;
          &:first-child {
            border-left-width: 0;
            &::after {
              width: 0;
            }
          }
          &:last-child {
            border-right-width: 0;
          }
        }
      }
    }
    td {
      border-color: white;
      border-style: solid;
      border-width: 0 1px 1px;
      white-space: nowrap !important;
      &:first-child {
        border-left-width: 0;
        &::after {
          background: $secondary-color;
        }
      }
      &:last-child {
        border-right-width: 0;
      }

      i.mdi-check,
      i.mdi-toggle-switch {
        color: #1c7430;
      }

      i.mdi-close {
        color: red;
      }
    }
  }
}
