.btn-fullscreen {
  position: absolute !important;
  margin-top: 15px;
  margin-left: 19px;
  width: 32px !important;
  height: 32px !important;
  background: white !important;
}

.btn-logout {
  position: absolute !important;
  margin-top: 55px;
  margin-left: 19px;
  width: 32px !important;
  height: 32px !important;
  background: #ff0000 !important;
}

.sz-btn {
  width: auto;
}

