@font-face {
  font-family: "gaia-icons";
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/icomoon.woff) format("woff");
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(/fonts/MaterialIcons-Regular.woff2) format('woff2'),
       url(/fonts/MaterialIcons-Regular.woff) format('woff'),
       url(/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
  &.material-icons-24px{
    font-size: 24px;
  }
  &.material-icons-36px{
    font-size: 36px;
  }
}

.esri-icon-visible {
  font-size: 25px;
  font-family: "gaia-icons" !important;
}

.esri-icon-non-visible {
  font-size: 25px;
  font-family: "gaia-icons" !important;
}

.gaia-icons {
  display: block;
  font-size: 45px;
  font-family: "gaia-icons" !important;

  &.gaia-icon-file {
    &::before {
      content: "\e900";
    }

    &.active {
      &::before {
        content: "\e903";
      }
    }
  }

  &.gaia-icon-pipeline {
    &::before {
      content: "\e901";
    }

    &.active {
      &::before {
        content: "\e905";
      }
    }
  }

  &.gaia-icon-map-marker {
    &::before {
      content: "\e902";
    }

    &.active {
      &::before {
        content: "\e904";
      }
    }
  }
}
